import {Component, ViewChild} from '@angular/core';
import {IonRouterOutlet, MenuController, NavController, Platform} from '@ionic/angular';
import {SidenavMenuService} from './services/sidenav-menu/sidenav-menu.service';
import {Menu} from './interfaces/menu.interface';
import {Observable} from 'rxjs';
import {LanguageService} from './services/language/language.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './services/auth/auth.service';
import User from './interfaces/user.interface';
import {Router} from '@angular/router';
import {AlertService} from './services/components/alert.service';
import {LoadNotificacionService} from './services/components/load-notificacion.service';
import {ModalService} from './services/modal/modal.service';
import {ConfigurationService} from './services/config/configuration.service';
import {NotificationService} from './services/notification/notification.service';
import {Storage} from '@ionic/storage';
import {OrderService} from './services/order/order.service';
import {GeolocationService} from './services/geolocation/geolocation.service';
import {ApiService} from './services/api/api.service';
import Truck from './interfaces/truck.interface';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Browser} from '@capacitor/browser';
import {SplashScreen} from '@capacitor/splash-screen';
import {AppTrackingStatusResponse, AppTrackingTransparency,} from 'capacitor-plugin-app-tracking-transparency';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {


  @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;
  public menuItems: Observable<Menu[]>;

  public user: User;

  constructor(
    private platform: Platform,
    private _sideNavService: SidenavMenuService,
    private _languageService: LanguageService,
    private _translate: TranslateService,
    private _authService: AuthService,
    private _configService: ConfigurationService,
    private _router: Router,
    private _loader: LoadNotificacionService,
    private _alertService: AlertService,
    private orderService: OrderService,
    private _modalService: ModalService,
    private _navCtrl: NavController,
    private _geolocation: GeolocationService,
    private menuController: MenuController,
    private _notification: NotificationService,
    private _storage: Storage,
    private _apiService: ApiService,
  ) {
    this._storage.create().then(it => {
      this.initializeApp();
      this.platform.resume.subscribe(async () => {
        await this.checkLoginDate()
      });
    });
  }


  public initializeApp() {
    this.platform.ready().then(async () => {
      SplashScreen.hide();

      await this._languageService.setInitialLanguage();
      //this.handleHardwareBackButton();
      const setStatusBarStyleDark = async () => {
        await StatusBar.setStyle({style: Style.Default});
      };
      StatusBar.setBackgroundColor({color: '#e7501e'});
      if (this.platform.is('android') || this.platform.is('ios'))
        window.screen.orientation.lock('portrait');
      await this.checkLoginDate().then(it => {
        if (it)
          this._authService.validateAuthentication();
      });
      if (this._authService.getUser()?.rol === 2) {
        this._geolocation.initBackgroundGeolocation(this.user, this._apiService);
      }
      if ( this.platform.is('ios')){
        this.requestPermission().then(it=>{

        })
      }
    });

  }

  private async checkLoginDate(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const truck: Truck = await this._storage.get('truck')
      if (truck) {
        this._apiService.lastDayCheckLogin(truck.id).subscribe(async (response) => {
          if (response.data.logout) {
            const token = await this._storage.get('token');
            this._apiService.logout(token, 1).subscribe(it => {
            })
            this._authService.logOut()
            resolve(false)
          } else {
            resolve(true)
          }

        })
      } else {
        resolve(false)
      }

    });
  }


  // private handleHardwareBackButton() {
  //   document.addEventListener('backbutton', () => {
  //     if (this._loader.isLoading) {
  //       this._loader.stopAlert();
  //       return;
  //     }

  //     if (this._modalService.isOpen) {
  //       this._modalService.dissmissModal();
  //       return;
  //     }

  //     switch (this._router.url) {
  //       case '/home':
  //         if (this._authService.getUser() !== null) {
  //           this._navCtrl.pop();
  //         }
  //         break;


  //       case '/':
  //         this._alertService.logOutAlert('ALERTS.EXIT-APP.message');
  //         break;

  //       case '/auth/login/signin':
  //         navigator['app'].exitApp();
  //         break

  //       default:
  //         this._navCtrl.pop();
  //         break;

  //     }
  //   }, false);

  // }

  public async getStatus(): Promise<AppTrackingStatusResponse> {
    return await AppTrackingTransparency.getStatus();
  }

  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();
    console.log(response);
    return response;
  }


}
