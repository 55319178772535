import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import Order from 'src/app/interfaces/order.interface';
import {AlertService} from '../components/alert.service';
import {Storage} from '@ionic/storage';
import {NavController} from '@ionic/angular';
import Truck from '../../interfaces/truck.interface';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    get request(): number {
        return this._request;
    }

    set request(value: number) {
        this._request = value;
    }
    get request_date(): Date {
        return this._requestDate;
    }

    set request_date(value: Date) {
        this._requestDate = value;
    }

    order: Order;
    private _request: number;
    private _requestDate: Date;
    constructor(private _http: HttpClient,
                private _alertService: AlertService,
                private _storage: Storage,
                private _navCtrl: NavController) {

    }


    public async saveOrder(order: Order): Promise<void> {

        this.order = order;
        this._storage.set('order', this.order);
    }

    public async savedOrder(): Promise<any> {

        return new Promise<any>(async (resolve, reject) => {
            const order = await this._storage.get('order') || null;
            if (order === null) {
                resolve(null)
            }
            this.order = order;
            resolve(this.order);
        });


    }

    public async saveDriverOrder(order: any) {
        this._storage.set('driverOrder', order);
    }

    public async getDriverOrder(): Promise<any> {
        return await this._storage.get('driverOrder') || null;
    }


    public saveTruck(truck: Truck) {

        this._storage.set('truck', truck);
    }

    public getTruck(): Promise<Truck> {
        return this._storage.get('truck');
    }




}
