import {BodyFormat} from './body-format';

export class UrlEncoded implements BodyFormat {
    contentType = 'application/x-www-form-urlencoded';

    format(values: any): string {
        const url = [];

        for (const name in values) {
            if (!values.hasOwnProperty(name)) {
                continue;
            }

            const value = values[name];

            url.push(this.getValueUrlEncoded(name, value));
        }

        return url.join('&');
    }

    private getValueUrlEncoded(name, value): string {
        if (typeof value === 'object') {
            return this.getObjectUrlEncoded(name, value);
        }

        return name + '=' + encodeURIComponent(value);
    }

    getObjectUrlEncoded(name, value): string {
        const result: string[] = [];

        for (const key in value) {
            if (!value.hasOwnProperty(key)) {
                continue;
            }

            result.push(this.getValueUrlEncoded(name + '[' + key + ']', value[key]));
        }

        return result.join('&');
    }
}
