import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrderService} from '../order/order.service';
import User from 'src/app/interfaces/user.interface';
import {ApiService} from '../api/api.service';
import {Subscription} from 'rxjs';
import {Geolocation, PositionOptions} from '@capacitor/geolocation';
import {registerPlugin} from '@capacitor/core';
import {BackgroundGeolocationPlugin} from '@capacitor-community/background-geolocation';

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>('BackgroundGeolocation');

// var google: any;
@Injectable({
    providedIn: 'root'
})
export class GeolocationService {

    private locationAPI: string;

    // public directionService = new google.maps.DirectionsService;
    private _apiService: ApiService;
    private test2: Subscription;
    private end: boolean = true;
    private watcher_id: Promise<string>;

    constructor(
        private _http: HttpClient,
        private orderService: OrderService) {

    }


    public initBackgroundGeolocation(user: User, apiService: ApiService) {
        const config: PositionOptions = {
            enableHighAccuracy: true,
            timeout: 500,
            maximumAge: 500,
        };
        this._apiService = apiService;

        this.onBackgroundMove(user);
    }

    public stopGeolocation() {

    }

    public onBackgroundMove(user: User) {
        var self=this;
        this.watcher_id = BackgroundGeolocation.addWatcher(
            {
                backgroundMessage: 'Cancelar para dejar de funcionar',
                backgroundTitle: 'Geolocalización en curso',
                requestPermissions: true,
                stale: false,
                distanceFilter: 1
            },
            async function callback(location, error) {
                if (error) {
                    BackgroundGeolocation.openSettings();

                    return console.error(error);
                }
            if (user.rol === 2) {
                const truck = await self.orderService.getTruck()
                if (truck) {
                    console.log('tracking...')
                    console.log('posicion', location.longitude.toString() + ';' + location.latitude.toString())
                    if (self.end) {
                        self.end = false
                        self._apiService.updateTruckLocation(truck, location.longitude.toString() + ';' + location.latitude.toString()).subscribe(it => {
                            self.end = true
                    }, onerror => {
                        self.end = true
                    },()=>{
                    });

            }}}}
        );
    }

    public onForegroundMove(user: User) {

    }


}
