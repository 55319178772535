import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import Order from 'src/app/interfaces/order.interface';
import { OrderService } from '../order/order.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchTermSubject = new BehaviorSubject<Order>(this.__orderService.order);
  searchTerm$ = this.searchTermSubject.asObservable();

  public searchFromMenu = new Subject<string>();

  setSearchTerm(term: Order) {
    this.searchTermSubject.next(term);
  }

  constructor(private __orderService:OrderService) { }
}

