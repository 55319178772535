import {Component, OnInit} from '@angular/core';
import {LanguageService} from 'src/app/services/language/language.service';
import {AlertService} from 'src/app/services/components/alert.service';
import { SidenavMenuService } from 'src/app/services/sidenav-menu/sidenav-menu.service';
import { ThemeServiceService } from 'src/app/services/theme-service/theme-service.service';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {

    constructor(
        private _languageService: LanguageService,
        private _alertService: AlertService,
        private _sidenavMenu: SidenavMenuService,
        private themeService: ThemeServiceService) {
    }


    language: string;

    async ngOnInit() {
        this.language = await this._languageService.getCurrentLang();
    }


    public selectLang(currentValue: string, compareValue: string) {

        return currentValue && compareValue ? currentValue === compareValue : '';

    }

    public changeLang(value) {
        this.language = value.target.value;
    }

    public save() {
        this._sidenavMenu.loadMenu(this.language);
        this._languageService.setLanguage(this.language);
        console.log(this.language)

        setTimeout(() => {
            this._alertService.presentAlertGreatSmall('ALERTS.configurationAlert.message');
        }, 300);
    }

      // Método para obtener el valor de DarkTheme del servicio
      public  esdark(): boolean {
        return this.themeService.isDarkTheme();
  }
}
