import {Params} from '@angular/router';
import {environment} from '../../../environments/environment';

declare var restUrlBase;

export class Url {
    private _pathParts: string[] = [];
    params: Params = {};
    queryParams: Params = {};
    readonly rest: boolean;
    readonly urlParamsNames: string[] = [];

    private static stripSlashes(path) {
        return path.replace(/^\/*|\/*$/g, '');
    }

    constructor(path: string | string[], rest: boolean, params: Params = {}, queryParams: Params = {}) {
        this.rest = rest;
        this.pathParts = (typeof path === 'string' ? [path] : path);
        this.urlParamsNames = this.getUrlParamsNames();
        this.params = params;
        this.queryParams = queryParams;
    }

    private getUrlParamsNames(): string[] {
        const matches = this.path.match(/\{[^\}]+\}/g);
        if (matches === null) {
            return [];
        }

        return matches.map(item => item.substr(1, item.length - 2));
    }

    addUrlParam(name: string, value: any) {
        this.params[name] = value;
    }

    addUrlParams(params: Params) {
        this.params = {...this.params, ...params};
    }

    set pathParts(path: string[]) {
        this._pathParts = path.map(item => Url.stripSlashes(item));
    }

    get pathParts(): string[] {
        return this._pathParts;
    }

    get path(): string {
        let path = Url.stripSlashes(this._pathParts.join('/'));

        const queryParams = this.queryParamsUri;

        if (queryParams) {
            path += path.indexOf('?') === -1 ? '?' : '&';

            path += queryParams;
        }

        for (const key in this.params) {
            if (this.params.hasOwnProperty(key)) {
                path = path.replace('{' + key + '}', this.params[key]);
            }
        }

        if (this.rest) {
            path = Url.stripSlashes(environment.apiRoot) + '/' + path;
        }

        return path;
    }

    get queryParamsUri(): string {
        const queryParamsParts: string[] = [];
        for (const key in this.queryParams) {
            if (this.queryParams.hasOwnProperty(key)) {
                queryParamsParts.push(encodeURIComponent(key) + '=' + encodeURIComponent(this.queryParams[key]));
            }
        }

        return queryParamsParts.join('&');
    }

    addPrefix(prefix: string) {
        this._pathParts.unshift(Url.stripSlashes(prefix));
    }

    addSuffix(suffix: string) {
        this._pathParts.push(Url.stripSlashes(suffix));
    }
}
