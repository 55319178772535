import {Injectable} from '@angular/core';
import {CanLoad} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanLoad {

    constructor(private _auth: AuthService, private navController: NavController) {
    }

    canLoad(): boolean | Observable<boolean> | Promise<boolean> {
        return this._auth.isAuth().then(response => {
            if (response) {
                return true
            } else {
                this.navController.navigateBack('/auth/login/signin');
                return false
            }
        });
    }
}
 