import {EventEmitter, Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    vibration: boolean;
    notifications: boolean;
    configEmitter: EventEmitter<any> = new EventEmitter();

    constructor(private _storage: Storage, private platform: Platform) {


    }

     public async initialConfig(active:boolean) 
     {
        this.setNotifications(active);
     }

     public setNotifications(active: boolean): void {
         this._storage.set('notifications', active);
         this.notifications = active;
         this.configEmitter.emit({notifications: active});
     }

     public ChangeNotifications(set: boolean) : void 
     {
        this.notifications = set;
     }

     public async getNotifications(): Promise<boolean> {

         return this.notifications = await this._storage.get('notifications');
     }
}
