import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeServiceService {
  private isDarkMode = false;
  private isDarkModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    constructor(private _storage: Storage) {
    
     this.loadDarkMode();
 }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    document.body.classList.toggle('dark-mode', this.isDarkMode);
    this._storage.set('darkmode',  this.isDarkMode);
    this.isDarkModeSubject.next(this.isDarkMode); // Emitir el valor actual del tema oscuro
  }

  setDarkMode(isDarkMode)
  {
    this._storage.set('darkmode',  isDarkMode);
  }

  getDarkMode()
  {
    return this._storage.get('darkmode');
  }

   isDarkTheme(): boolean {
     return this.isDarkMode;
   }

   public async loadDarkMode() {
    try {
      this.isDarkMode = await this._storage.get('darkmode');
      this.isDarkModeSubject.next(this.isDarkMode); // Emitir el valor actual del tema oscuro
    } catch (error) {
      console.error('Error al cargar el tema oscuro:', error);
    }
  }

   public getIsDarkModeObservable() {
    return this.isDarkModeSubject.asObservable();
  }

}