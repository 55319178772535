import {RestRequest} from './rest-request';
import {Observable, Observer} from 'rxjs';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Params} from '@angular/router';
import {Url} from './url';
import {RestService} from '../../services/rest/rest.service';
import {BodyFormat} from './body-format';
import {UrlEncoded} from './url-encoded';
import {RestResponse} from './rest-response';
import {ResponseStructure} from './response-structure';
import {BaseResponse} from './base-response';

export class RequestBase<T> implements RestRequest<T> {
    private static globalHeaders: { [name: string]: string };
    bodyData: any = {};
    handle401Error = true;
    protected bodyFormatter: BodyFormat = new UrlEncoded();
    type = 'GET';
    url: Url;
    lockScreen = true;
    transparent = false;
    addRequestParams = false;
    afterPreconditions = true;
    isAnonymous = false;
    private lastResponse: BaseResponse<T>;
    private error: boolean;

    constructor(url: Url, method: string, private restService: RestService) {
        this.url = url;
        this.type = method;
        // this.bodyData['XDEBUG_SESSION_START'] = 'PHPSTORM';
    }

    static addGlobalHeaders(headers: { [name: string]: string }) {
        RequestBase.globalHeaders = {...RequestBase.globalHeaders, ...headers};
    }

    addRequestValues(values: { [name: string]: any }) {
        for (const name in values) {
            if (values.hasOwnProperty(name)) {
                this.addRequestValue(name, values[name]);
            }
        }
    }

    get params(): Params {
        if (this.type === 'GET' || this.type === 'DELETE') {
            return this.url.queryParams;
        } else {
            return this.bodyData;
        }
    }

    addRequestValue(name: string, value: any): void {
        if (!value && value !== 0) {
            value = '';
        }

        name = name.replace('.', '__');

        this.params[name] = value;
    }

    removeRequestValue(name: string) {
        name = name.replace('.', '_');
        if (name in this.params) {
            delete this.params[name];
        }
    }

    getRequestValue(name: string): any {
        name = name.replace('.', '_');
        return name in this.params ? this.params[name] : undefined;
    }

    get body(): string {
        if (!this.bodyAllowed) {
            return null;
        }

        return this.bodyFormatter.format(this.bodyData);
    }

    get bodyAllowed(): boolean {
        return this.type !== 'GET' && this.type !== 'DELETE';
    }

    execute(): Observable<BaseResponse<T>> {
        return new Observable((observer: Observer<BaseResponse<T>>) => {
            if (this.lastResponse !== undefined) {
                if (!this.error) {
                    observer.next(this.lastResponse);
                } else {
                    observer.error(this.lastResponse);
                }
            } else {
                this.restService.execute(this).subscribe((result: RestResponse<T>) => {
                        this.lastResponse = this.createResponse(result, this.url.path);
                        this.error = false;
                        /* if (this.lastResponse.shouldShowMessage()) {
                             this.lastResponse.showMessage();
                         }*/
                        console.log(result);
                        observer.next(this.lastResponse);
                        observer.complete();
                    },
                    (errorData: any) => {
                        this.lastResponse = new RestResponse<any>(errorData, this.url.path);
                        this.error = true;
                        /*
                                                if (this.lastResponse.shouldShowMessage()) {
                                                    this.lastResponse.showMessage();
                                                }*/

                        observer.error(this.lastResponse);
                    });
            }
        });
    }


    get httpParams(): HttpParams {
        let params = new HttpParams();

        if (!this.bodyAllowed) {
            for (const key in this.bodyData) {
                if (this.bodyData.hasOwnProperty(key)) {
                    params = params.set(key, this.getRequestValue(key));
                }
            }
        }

        return params;
    }

    get headers(): HttpHeaders {
        return new HttpHeaders({...RequestBase.globalHeaders, ...{'Content-Type': this.bodyFormatter.contentType}});
    }

    protected createResponse(data: ResponseStructure<T>, path: string): BaseResponse<T> {
        return new RestResponse<T>(data, path);
    }


}
