import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ModalOptions} from '@ionic/core';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    public isOpen: boolean;
    public modalsOpened: any[] = [];

    constructor(public modalController: ModalController) {
    }

    async presentModal(options: ModalOptions,  cssClass: string = '') {
        options.cssClass = `ion-modal ${cssClass}`; // Agregar la clase ion-modal y la clase personalizada
        const modal = await this.modalController.create(options);
        this.isOpen = true;
        this.modalsOpened.push(modal);
        return await modal.present();


    }

    public dissmissModal(data?: any) {
        if (this.modalsOpened.length > 0) {
            this.isOpen = true;
            this.modalsOpened.pop();
        } else {
            this.isOpen = false;
        }

        return this.modalController.dismiss(data);
    }
}

