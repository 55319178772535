import {Injectable} from '@angular/core';
import {NavController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import Swal, {SweetAlertResult} from 'sweetalert2'

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(
        private _translateService: TranslateService,
        private _nav: NavController) {
    }



    public presentAlertReprogramado( message: string, params?: Object) {

       return  Swal.fire({
            iconHtml: '<img src="/assets/icon/reprogramado-cancelado.png" alt="">', 
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            width: "250px",
            text: this._translateService.instant(message, params),
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }

            
        })

    }

    public presentAlertOrderDelivered(message: string, orderCode: string, params?: Object)
    {
            // Agregar orderCode al objeto params
            params = { ...params, orderCode: orderCode };
    
            // Obtener el mensaje traducido
            const translatedMessage = this._translateService.instant(message, params);
    
            // Agregar el orderCode al mensaje
            const messageWithOrderCode = `${translatedMessage} - <strong>${orderCode}</strong>`;
        
            return Swal.fire({
                iconHtml: '<img src="/assets/icon/pedido_entregado.png" alt="">', 
                customClass: {
                    container: 'container-class',
                    title: 'title-class',
                    popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
                    icon: 'custom-swal-icon',
                },
                backdrop: false,
                showCancelButton: false, // Desactiva el botón de cancelar
                showConfirmButton: false, // Desactiva el botón de confirmar
                showCloseButton: true, // Muestra el botón de cerrar
                width: "250px",
                html: messageWithOrderCode,
                didOpen: () => {
                    const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                    const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                    if (swalPopup) {
                        swalPopup.style.color = '#061826';
                      }
                    if(swalicon)
                    {
                        swalicon.style.border= 'none' ;
                    }
                }
            });
        }


        public presentAlertOrderGreatDelivered(message: string, params?: Object)
        {
            return  Swal.fire({
                iconHtml: '<img src="/assets/icon/pedido_entregado.png" alt="">', 
                customClass: {
                    container: 'container-class',
                    title: 'title-class',
                    popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
                    icon: 'custom-swal-icon',
                },
                backdrop: false,
                showCancelButton: false, // Desactiva el botón de cancelar
                showConfirmButton: false, // Desactiva el botón de confirmar
                showCloseButton: true, // Muestra el botón de cerrar
                width: "250px",
                text: this._translateService.instant(message, params),
                didOpen: () => {
                    const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                    const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                    if (swalPopup) {
                        swalPopup.style.color = '#061826';
                      }
                    if(swalicon)
                    {
                        swalicon.style.border= 'none' ;
                        
                    }
                }
    
                
            })
              
        }

    public presentAlertOrderNextDay( message: string, orderCode: string, params?: Object) {
        // Agregar orderCode al objeto params
        params = { ...params, orderCode: orderCode };

        // Obtener el mensaje traducido
        const translatedMessage = this._translateService.instant(message, params);

        // Agregar el orderCode al mensaje
        const messageWithOrderCode = `${translatedMessage} - <strong>${orderCode}</strong>`;
    
        return Swal.fire({
            iconHtml: '<img src="/assets/icon/reprogramado-cancelado.png" alt="">', 
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            width: "250px",
            html: messageWithOrderCode,
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
        });
    }

      public presentAlertError( message: string, params?: Object) {
    
          return Swal.fire({
              iconHtml: '<img src="/assets/icon/cancelado.png" alt="">',
              customClass: {
                  container: 'container-class',
                  title: 'title-class',
                  popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
              },
              backdrop: false,
              showCancelButton: false, // Desactiva el botón de cancelar
              showConfirmButton: false, // Desactiva el botón de confirmar
              showCloseButton: true, // Muestra el botón de cerrar
              text: this._translateService.instant(message, params),
              width: "250px",
              didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
          });
      }


      public presentAlertGreat( message: string, params?: Object) {

        return  Swal.fire({
             iconHtml: '<img src="/assets/icon/checkmark.png" alt="">', 
             customClass: {
                 container: 'container-class',
                 title: 'title-class',
                 popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
             },
             backdrop: false,
             showCancelButton: false, // Desactiva el botón de cancelar
             showConfirmButton: false, // Desactiva el botón de confirmar
             showCloseButton: true, // Muestra el botón de cerrar
             width: "350px",
             text: this._translateService.instant(message, params),
             didOpen: () => {
                 const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                 const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                 if (swalPopup) {
                     swalPopup.style.color = '#061826';
                   }
                 if(swalicon)
                 {
                     swalicon.style.border= 'none' ;
                     
                 }
             }
 
             
         })
 
     }

     public presentAlertGreatSmall( message: string, params?: Object) {

        return  Swal.fire({
             iconHtml: '<img src="/assets/icon/checkmark.png" alt="">', 
             customClass: {
                 container: 'container-class',
                 title: 'title-class',
                 popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
             },
             backdrop: false,
             showCancelButton: false, // Desactiva el botón de cancelar
             showConfirmButton: false, // Desactiva el botón de confirmar
             showCloseButton: true, // Muestra el botón de cerrar
             width: "250px",
             text: this._translateService.instant(message, params),
             didOpen: () => {
                 const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                 const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                 if (swalPopup) {
                     swalPopup.style.color = '#061826';
                   }
                 if(swalicon)
                 {
                     swalicon.style.border= 'none' ;
                     
                 }
             }
 
             
         })
 
     }
    

    //TODO order-list DRIVER
    public restAlert() {

        return Swal.fire({
            title: this._translateService.instant('ALERTS.resting_title'),
            icon: 'warning',
            iconHtml: '<img src="/assets/icon/40x40.png" alt="">',
            customClass: {
                container: 'container-class',
                title: 'title-class',
                icon: 'disable-border'
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            text: this._translateService.instant('ALERTS.resting'),
        })

    }
    public DriverInactiveAlert(params?: Object)
    {
        return Swal.fire({
            iconHtml: '<img src="/assets/icon/acceso_prohibido.png" alt="">',
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            width: "250px",
            text: this._translateService.instant('ALERTS.signInError.user_message',params),
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
        })

    }

    public companyInactiveAlert( params?: Object) {

        return Swal.fire({
            iconHtml: '<img src="/assets/icon/acceso_prohibido.png" alt="">',
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
            },
            backdrop: false,
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            width: "250px",
            text: this._translateService.instant('ALERTS.signInError.message_company',params),
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
        })

    }

    //TODO Driver order-list
    public truckNoAvailableAlert() {

        return Swal.fire({
            title: this._translateService.instant('ALERTS.resting_no_truck'),
            icon: 'warning',
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            backdrop: false,
            text: this._translateService.instant('ALERTS.truck_no_available'),
        })

    }

    public truckNotOnline() {

        return Swal.fire({
            iconHtml: '<img src="/assets/icon/inicio_ruta.png" alt="">',
            customClass: {
                container: 'container-class',
                title: 'title-class',
                popup: 'custom-swal-popup', // Personaliza la clase del contenedor del modal
                icon: 'custom-swal-icon',
            },
            backdrop: false,
            width: "250px",
            showCancelButton: false, // Desactiva el botón de cancelar
            showConfirmButton: false, // Desactiva el botón de confirmar
            showCloseButton: true, // Muestra el botón de cerrar
            text: this._translateService.instant('ALERTS.truck_not_online'),
            didOpen: () => {
                const swalPopup = document.querySelector('.custom-swal-popup .swal2-close') as HTMLElement;
                const swalicon = document.querySelector('.swal2-icon.swal2-icon-show') as HTMLImageElement;
                if (swalPopup) {
                    swalPopup.style.color = '#061826';
                  }
                if(swalicon)
                {
                    swalicon.style.border= 'none' ;
                    
                }
            }
        })

    }

    

    public logOutAlert(message: string) {

        return Swal.fire({
            text: this._translateService.instant(message),
            html: `<ion-icon name="power"  style="color:#f91b7b; margin-bottom: 10px;"></ion-icon><br>${this._translateService.instant(message)}`,
            showCancelButton: true,
            confirmButtonColor: '#f91b7b',
            cancelButtonColor: '#1E2F3B',
            width: "250px",
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            confirmButtonText: this._translateService.instant('ALERTS.texts.yes'),
            cancelButtonText: this._translateService.instant('ALERTS.texts.no'),
            backdrop: false,

        })

    }

    //TODO map-driver.componente
    public showConfirmAlert(loaderText: string) {
        return Swal.fire({
            icon: 'success',
            backdrop: false,
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            confirmButtonText: this._translateService.instant('PLAN.PAYMENT.button'),
            confirmButtonColor: '#16de4b',
            text: this._translateService.instant(loaderText)
        })
    }

    //TODO map-driver
    public nextDayAlert(loaderText: string): Promise<SweetAlertResult> {
        return Swal.fire({
            text: this._translateService.instant(loaderText),
            icon: 'warning',
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            showCancelButton: true,
            backdrop: false,
            confirmButtonText: this._translateService.instant('ALERTS.texts.yes'),
            cancelButtonText: this._translateService.instant('ALERTS.texts.no'),
            cancelButtonColor: '#eb3936',
            confirmButtonColor: '#16de4b',
        })
    }

    // TODO map-diver
    public deleteAlert(loaderText: string): Promise<SweetAlertResult> {
        return Swal.fire({
            title: this._translateService.instant(loaderText),
            text: this._translateService.instant('ALERTS.deleteAlertSecure'),
            icon: 'warning',
            customClass: {
                container: 'container-class',
                title: 'title-class',
            },
            showCancelButton: true,
            backdrop: false,
            confirmButtonText: this._translateService.instant('ALERTS.texts.yes'),
            cancelButtonText: this._translateService.instant('ALERTS.texts.no'),
            cancelButtonColor: '#eb3936',
            confirmButtonColor: '#16de4b',
        })
    }


}
