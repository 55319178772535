import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit {

    /**
     * [0] default option -> list style.
     * [1] plan card style.
     */

    @Input('skeletonType') type: number = 0;

    constructor() {
    }

    ngOnInit() {
    }

}
